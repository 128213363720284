<template>
  <div class="output" v-html="currentHelpText"></div>

  <div class="buttons" v-if="loggedIn">
    <router-link to="/update">Opdater tekst</router-link>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/database";
export default {
  name: "HelpContent",

  props: {
    msg: String,
  },
  data: function() {
    return {
      currentHelpText: null,
      loggedIn: false,
    };
  },
  methods: {
    getContent() {
      this.getHelpContent();
    },

    getHelpContent() {
      const vm = this;
      var disclaimer = firebase.database().ref("/content/help");
      disclaimer.on("value", (snapshot) => {
        const data = snapshot.val();

        if (Object.values(data).length) {
          vm.helpUID = Object.keys(data)[0];
          vm.currentHelpText = Object.values(data)[0].text;
        }
        vm.newHelpText = vm.currentHelpText;
      });
    },
  },
  mounted() {
    this.getContent();
    this.loggedIn = this.$store.state.loggedIn;
  },
};
</script>

<style scoped lang="scss"></style>
