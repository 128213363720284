<template>
  <div class="container text">
    <div class="text__content">
      <HelpContent />
    </div>
  </div>
</template>

<script>
import HelpContent from "@/components/HelpContent.vue";
export default {
  name: "Help",
  components: { HelpContent },
};
</script>
